<!--suppress XmlHighlighting, XmlHighlighting -->
<template>
  <div>
    <PageHeaderLayout>
      <div class='main-page-content'>
        <el-form ref='artistForm' :model='formData' :rules='rules' :inline='false'
                 label-position='right'
                 label-width='80px'>
          <el-row :gutter="30">
            <el-col :span="10">
              <div class="info_box">
                <div class="main">
                  <p class="main__title">基本信息
                    <el-link @click="handleLog" style="float: right;line-height: 30px" type="primary">操作记录</el-link>
                  </p>
                  <hr/>
                </div>
                <div class="info_content">
                  <el-row :gutter="20">
                    <el-col :span="6">
                      <el-form-item label="红人昵称" prop="nickname">
                        <span class="sign-item"> {{ formData.nickname }}  </span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="签约状态" prop="title">
                        <span class="sign-item">{{ formData.sign_status == 'Y' ? '已签约' : '已解约' }}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="签约时间" prop="title">
                        <span class="sign-item">{{ $utils.parseTime(formData.sign_start, '{y}/{m}/{d}') }}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="6">
                      <el-form-item label="合约到期" prop="title">
                        <span class="sign-item"> {{ $utils.parseTime(formData.sign_end, '{y}/{m}/{d}') }}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="24">
                      <el-form-item label="红人备注" prop="remark">
                        <span class="sign-item"> {{ formData.remark }}</span>
                      </el-form-item>
                    </el-col>
                    <el-col :span="21">
                      <el-form-item label='肤质' style="width: 100%" prop="skin_type">
                        <el-input v-model='formData.skin_type' placeholder='请输入KOL肤质' maxlength='50'
                                  style="width: 100%;display: block" :show-word-limit='true' :clearable='true'/>
                      </el-form-item>
                    </el-col>
                    <el-col :span="21">
                      <el-form-item label='标签' style="width: 100%" prop="kol_label">
                        <el-input v-model='formData.kol_label' placeholder='请输入KOL标签,多个标签用顿号（”、“）隔开'
                                  maxlength='100' :show-word-limit='true' :clearable='true'/>
                      </el-form-item>
                    </el-col>
                    <el-col :span="21">
                      <el-form-item label='直播类型' style="width: 100%" prop="live_type">
                        <el-radio-group v-model='formData.live_type'>
                          <el-radio :label='liveTypeOption.value' :value='liveTypeOption.value'
                                    v-for='(liveTypeOption) in liveTypeOptions' :key='liveTypeOption.value'>
                            {{ liveTypeOption.name }}
                          </el-radio>
                        </el-radio-group>
                      </el-form-item>
                    </el-col>
                  </el-row>
                </div>
              </div>
            </el-col>
            <el-col :span="14">
              <div class="info_box">
                <div class="main">
                  <p class="main__title">
                    数据平台信息
                    <span style="font-size: 14px">
                （营收数据、直播数据等，需要开通，才可使用）
              </span>
                  </p>
                  <hr/>
                  <div class='div-block'>
                    <!--                 v-if="Number(item.is_data_entry)===1"-->
                    <el-row v-for='(item,index) in platformsData' :key='item.id' :gutter='20'
                            style="margin-bottom: 10px">
                      <div>
                        <el-col :span='3'>
                          <div style='display: flex;align-items: center; justify-content: center;'>
                            <el-image
                                class='platform-img'
                                :src='item.logo_url'
                                fit='contain'>
                            </el-image>
                            <span class='platform-title'>{{ item.name }}：</span>
                          </div>
                        </el-col>
                        <el-col :span='21'>
                          <div style='text-align: center;height: 30px;line-height: 30px;'>
                            <el-row>
                              <el-col :span="5">
                                <el-radio-group v-model='formData.platforms[item.code]'>
                                  <el-radio label='N' value='N'>无</el-radio>
                                  <el-radio label='Y' value='Y'>有</el-radio>
                                </el-radio-group>
                              </el-col>
                              <el-col :span="19">
                                <el-form-item :required="formData.platforms[item.code]==='Y'"
                                              label="平台ID">
                                  <div style="text-align: left">
                                    <el-tag
                                        style="margin-left: 10px"
                                        :key="index"
                                        effect="plain"
                                        v-for="(tag,index) in formData.platforms_account_id[item.code]"
                                        closable
                                        size="small"
                                        @close="handleClose(item.code,index)"
                                        :disable-transitions="false"
                                    >
                                      {{ tag }}
                                    </el-tag>
                                    <el-input
                                        class="input-new-tag"
                                        v-if="item.inputVisible"
                                        v-model="appendData[item.code]"
                                        size="mini"
                                        style="margin-left: 10px;display: inline-block;width: 100px"
                                        @keyup.enter.native="handleInputConfirm(item)"
                                        @blur="handleInputConfirm(item)"
                                    >
                                    </el-input>
                                    <el-button style="margin-left: 10px;display: inline-block"
                                               size="mini" v-else class="button-new-tag" @click="showInput(index)">+ ID
                                    </el-button>
                                  </div>

                                </el-form-item>
                              </el-col>
                            </el-row>
                          </div>
                        </el-col>
                      </div>
                    </el-row>
                  </div>
                </div>
              </div>
            </el-col>
          </el-row>
        </el-form>
        <div>
          <div class='delBox' v-if="userPermissions.indexOf('artist_edit') !== -1">
            <el-button type="primary" size="big" @click="saveConfirm">保存信息</el-button>
            <el-button size="big" @click="goBack">取 消</el-button>
            <!--          <el-button type='danger' plain icon='el-icon-delete' @click='handleDelete'>删除红人</el-button>-->
          </div>
        </div>


        <div class="info_box" style="overflow:hidden;">
          <div class="main">
            <p class="main__title"> 刊例平台信息
              <el-link type="primary" style="float: right;line-height: 30px" @click="showKolPlatformInfo()">
                添加平台
              </el-link>
            </p>
            <hr/>
          </div>
          <div>
            <el-row style="width: 96%;margin: 0px auto" :gutter="20">
              <el-col :span="6" style="margin-bottom: 20px" v-for="item in kolPlatform" :key="item.id">
                <el-card shadow="hover" style="cursor: pointer">
                  <div slot="header" class="clearfix" style="overflow: hidden">
                    <span
                        style="float: right;display: flex;justify-content: center;align-items: center">
                         <el-image
                             style="width: 16px;height: 16px;margin-right: 4px"
                             :src='item.platform_logo_url'
                             fit='contain'>
                      </el-image>{{ item.platform_name }}
                    </span>
                  </div>
                  <div>
                    <el-form :label-suffix="'：'">
                      <el-row :gutter="20">
                        <el-col :span="18">
                          <el-form-item label="平台ID">
                            <span :title="item.account_id" class="multi-line-ellipsis" style="-webkit-line-clamp:1">
                                     {{ item.account_id }}
                            </span>
                          </el-form-item>
                          <el-form-item label="经纪人">
                            {{ item.agent_name }}
                          </el-form-item>
                          <el-form-item label="城市">
                            {{ item.city }}
                          </el-form-item>

                        </el-col>
                      <el-col :span="4">
                        <div class="kol_logo">
                          <el-image
                              style="width: 100%;height: 100%;display: block;border-radius: 50%"
                              :src='item.cover_url'
                              fit='contain'>
                          </el-image>
                        </div>
                      </el-col>
                      </el-row>
                      <el-row style="height: 120px">
                        <el-form-item label="主页链接">
                          <template slot="label">
                            主页链接
                            <el-link :title="'展开'" type="primary" class="el-icon-warning-outline"
                                     @click="checkLink(item)"
                                     style="font-size: 14px;display: inline-block;z-index: 99;position: relative"></el-link>
                          </template>

                          <span :title="item.home_page_link"
                                class="multi-line-ellipsis" style="-webkit-line-clamp:1">
                            {{ item.home_page_link }}
                          </span>

                        </el-form-item>
                        <el-form-item label="简介">
                          <span :title="item.intro" class="multi-line-ellipsis">{{ item.intro }}</span>
                        </el-form-item>
                      </el-row>

                      <div style="float: right;padding: 10px 0;">
                        <el-link @click="showKolPlatformInfo(item)" type="primary" class="el-icon-edit"
                                 style="margin: 0 10px">修改
                        </el-link>
                        <el-link type="primary" @click="delItem(item)" class="el-icon-delete">删除</el-link>
                      </div>
                    </el-form>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
        </div>
        <!--        <el-dialog-->
        <!--            :visible.sync='deleteVisible'-->
        <!--            width='30%'-->
        <!--        >-->
        <!--          <template slot='title'>删除红人<span style='color: #d1242f'>  {{ formData.nickname }}</span></template>-->
        <!--          <div class='delInput'>-->
        <!--            <i class='el-icon-delete' style='color: #d1242f;font-size: 30px;text-align: center;display: block'></i>-->
        <!--            <br/>-->
        <!--            <b style='text-align: center;color: #1f2328'>-->
        <!--              确认删除，在输入框输入 <span style='color: #d1242f'> {{ formData.nickname }}</span>-->
        <!--            </b>-->
        <!--            <el-input oninput="value = value.replace(/\s/g, '')" v-model='delNickname'-->
        <!--                      style='display: block;width: 80%;margin: 20px auto;' width='80%'></el-input>-->
        <!--          </div>-->
        <!--          <span slot='footer' class='dialog-footer'>-->
        <!--    <el-button @click='deleteVisible = false'>取 消</el-button>-->
        <!--    <el-button type='primary' @click='sureDelete'>确 定</el-button>-->
        <!--  </span>-->
        <!--        </el-dialog>-->
        <!--        日志-->
        <!--        <div style='padding: 10px;width: 90%;min-width: 800px;border-radius: 4px;border: #f2f2f2 1px solid'>
                </div>-->
      </div>
      <el-dialog
          width="50%"
          :visible.sync="LogdialogVisible"
      >
        <ArtistLog :artist-id='this.formData.id'/>

      </el-dialog>
      <el-dialog
          :title="`${editForm?.id?'修改':'增加'}刊例平台信息`"
          :visible.sync="dialogVisible"
          width="50%"
      >
        <KolPlatformInfo @close="close" :platforms="platformsPlcData" ref='refKolPlatformInfo' :formData="editForm"/>
      </el-dialog>
      <el-dialog
          :title="infoDialog.title"
          :visible.sync="infoDialog.show"
          width="20%"
      >
        <span>{{ infoDialog.info }}</span>
        <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="infoDialog = false">确 定</el-button>
  </span>
      </el-dialog>

    </PageHeaderLayout>
  </div>
</template>

<script>
import PageHeaderLayout from '@/layouts/PageHeaderLayout'
import {mapGetters} from 'vuex'
import KolPlatformInfo from '@/pages/artist/KolPlatformInfo'
import ArtistLog from '@/pages/artist/ArtistLog'

const data = {
  artist_id: '',
  code: '',
  account_id: '',
  home_page_link: '',
  agent_name: '',
  city: '',
  intro: '',
  cover_url: null
}
export default {
  name: 'ArtistCreateEdit',
  components: {
    ArtistLog,
    KolPlatformInfo,
    PageHeaderLayout
  },
  data() {
    return {
      artistId: null, // 红人id，null表示创建，非null为编辑
      platforms: [],
      squareUrl: 'https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png',
      formData: {
        nickname: '',
        group_id: null,
        group_time: null,
        sign_status: 'Y',
        platforms: {},
        platforms_account_id: {},
        sign_start: null,
        sign_end: null,
        fired_at: null,
        remark: '',
        avatar: 'https://eziyan.oss-cn-hangzhou.aliyuncs.com/Logo/logo_108_108.png',
        skin_type: '',
        live_type: null,
        kol_label: ''
      },
      liveTypeOptions: [
        { value: 1, name: '达播' },
        { value: 2, name: '日播' }
      ],
      rules: {
        skin_type: [
          { required: true, message: '输入肤质必填', trigger: 'blur' }
        ],
        live_type: [{ required: true, message: '直播类型必选', trigger: 'blur' }],
        kol_label: [{ required: true, message: '标签必填', trigger: 'blur' }],
        platforms_account_id: [{ required: true, message: '平台ID必填', trigger: 'blur' }]
      },
      deleteVisible: false,
      delNickname: '',
      kolPlatform: [],
      dialogVisible: false,
      editForm: {
        ...data
      },
      LogdialogVisible: false,
      infoDialog: {
        show: false,
        info: '',
        title: ''
      },
      inputVisible: false,
      inputValue: '',
      appendData: {}
    }
  },
  computed: {
    ...mapGetters(['userPermissions']),
    platformsData() {
      return this.platforms.map((item) => {
        item.inputVisible = false
        return Number(item.is_data_entry) === 1 ? item : null
      }).filter(Boolean)
    },
    platformsPlcData() {
      return this.platforms.map((item) => {
        item.inputVisible = false
        return Number(item.is_plc_entry) === 1 ? item : null
      }).filter(Boolean)
    }
  },
  created() {
    this.initForm()
  },
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    checkLink(item) {
      this.infoDialog.info = item.home_page_link
      this.infoDialog.show = true
      this.infoDialog.title = `${item.platform_name} 平台主页链接`
    },
    close() {
      this.dialogVisible = false
      this.getKolPlatform()
    },
    defaultPlatforms() {
      // 平台账号：默认有账号
      let platforms = {}
      this.platformsData.forEach((platform) => {
        platforms[platform.code] = 'N'
      })
      this.formData.platforms = platforms
    },
    defaultPlatformsId() {
      let platforms_account_id = {}
      this.platformsData.forEach((platform) => {
        platforms_account_id[platform.code] = null
      })

      // this.formData.platforms_account_id = platforms_account_id
      this.$set(this.formData, 'platforms_account_id', platforms_account_id)
    },
    async initForm() {
      //分组列表
      await this.getPlatforms()
      // 签约状态：默认签约
      this.formData.sign_status = 'Y'
      this.defaultPlatforms()
      this.defaultPlatformsId()
      // 获取到ID：编辑
      if (this.$route.params.artist_id) {
        this.artistId = this.$route.params.artist_id
      }
      if (this.artistId) {
        await this.getArtistDetail()
        await this.getKolPlatform()
      }

    },
    async getArtistDetail() {
      this.deleteVisible = false
      let { info } = await this.$api.getArtistInfo(this.artistId)
      this.formData = info

      // this.formData.avatar = 'https://eziyan.oss-cn-hangzhou.aliyuncs.com/Logo/logo_108_108.png'
      this.formData.avatar = 'http://game-admin.pblab.com/api/pub_upload/2022-02-25/ci4vd60uaoi0osus7r.jpg'
      if (!this.formData.platforms) {
        this.defaultPlatforms()
      } else {
        this.filterPlatforms()
      }
      if (!this.formData.platforms_account_id) {
        this.defaultPlatformsId()
      }
    },
    filterPlatforms() {
      let newPlatforms = {}
      this.platformsData.forEach((platform) => {
        newPlatforms[platform.code] = this.formData.platforms[platform.code]
      })
      this.formData.platforms = newPlatforms
    },
    // 返回列表
    returnList() {
      this.$router.push('/artist')
    },
    // 确认保存按钮
    async saveConfirm() {
      // 调用组件的数据验证方法
      this.$refs['artistForm'].validate((valid) => {
        if (valid) {
          this.formSubmit()
        } else {
          this.$message.error('数据验证失败，请检查必填项数据！')
        }
      })
      return true
    },
    // 相关信息的保存处理
    async formSubmit() {
      //只传允许修改的字段
      let msg = false
      for (const platformsKey in this.formData.platforms) {
        if (this.formData.platforms[platformsKey] === 'Y' && !this.formData.platforms_account_id[platformsKey]) {
          msg = true
        }
      }
      if (msg) {
        this.$message.error('平台开通,则平台ID必填')
        return false
      } else {
        let postData = {
          id: this.formData.id,
          platforms: this.formData.platforms,
          platforms_account_id: this.formData.platforms_account_id,
          kol_label: this.formData.kol_label,
          skin_type: this.formData.skin_type,
          live_type: this.formData.live_type
        }
        let id = await this.$api.saveArtist(postData)
        if (id) {
          this.$notify.success('保存成功！')
          await this.initForm()
        }
        if (!this.artistId && id) {
          this.$router.push(this.$route.matched[1].path)
        }
      }


    },
    async getPlatforms() {
      // 获取最新平台信息
      let { list } = await this.$api.getPlatformAll({ status: 1 })
      this.platforms = list
      // 缓存平台信息
      localStorage.setItem('platforms', JSON.stringify(list))

    },
    showKolPlatformInfo(item) {
      if (item) {
        this.editForm = item
      } else {
        this.editForm = { ...data }
        this.editForm.artist_id = this.formData.id
      }
      this.dialogVisible = true
    },
    handleDelete() {
      this.deleteVisible = true
    },
    async delItem(item) {
      this.$confirm(`确认删除该条数据?`, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        let id = await this.$api.delArtistPlatformInfo(item.id)
        if (id) {
          this.$message.success('删除成功~')
          await this.getKolPlatform()
        }
      })
    },
    // 相应删除按钮
    async deleteButton(id) {
      let info = await this.$api.deleteArtist(id)
      if (info === 'ok') {
        this.$message.success('删除成功')
        this.deleteVisible = false
        this.$router.push('/artist')
      } else {
        this.$message.error(info)
      }
    },
    sureDelete() {
      if (this.delNickname === this.formData.nickname) {
        this.deleteButton(this.formData.id)
      } else {
        this.$message.error(`请输入正确内容： ${this.formData.nickname}`)
      }

    },
    async getKolPlatform() {
      let { list } = await this.$api.getKolJournalList({ artist_id: this.artistId })
      this.kolPlatform = list
    },
    handleLog() {
      this.LogdialogVisible = true
    },
    handleClose(code,index) {
      this.formData.platforms_account_id[code].splice(index, 1);
    },

    showInput(index) {
      this.$set(this.platformsData[index], 'inputVisible', true)
      this.$forceUpdate()

    },

    handleInputConfirm(item) {
      let code = item.code
      this.formData.platforms_account_id[code].push(this.appendData[code])
      this.appendData[code] = null
      item.inputVisible = false
      this.$forceUpdate()
    }
  }
}
</script>

<style>
.div-block {
  -webkit-appearance: none;
  background-color: #FFFFFF;
  background-image: none;
  border-radius: 4px;
  //border: 1px solid #DCDFE6;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #606266;
  font-size: inherit;
  outline: none;
  padding: 0 15px;
  -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  margin: 10px auto;

}

.platform-img {
  width: 30px;
  height: 30px;
  flex: 1;
  text-align: right
}

.platform-title {
  font-weight: bold;
  margin-left: 10px;
  line-height: 22px;
  flex: 4;
  text-align: left;
}

.content-left {
  border-right: 1px solid #e8e8e8
}

.sign-info {
  text-align: left;
  width: 80px;
  display: inline-block;
}

.sign-item {
  border-bottom: 1px dashed #DCDFE6;
  width: 200px;
  text-align: center;
}

.delBox {
  display: inline-block;
  text-align: center;
  //margin: 10px auto;
  /*position: absolute;*/
  /*right: 10px;*/
  /*bottom: 10px;*/
  width: 90%;
  margin: 0 auto;
  padding-top: 20px;

}

.delButton {
  display: block;
  text-align: center;
  margin: 0 auto;
  background-color: #F6F8FB;
  border: 1px solid #d0d7de;
  color: #d1242f;
}

.delInput {
  text-align: center;
}

.delButton:hover {
  background-color: #d1242f;
  color: white;
}


</style>
<style lang="scss" scoped>
.info_box {
  .main {
    background: #ffffff;
    border-radius: 20px;
    color: rgba(0, 0, 0, .85);
    padding: 5px 16px;
    margin-bottom: 10px;
    margin-top: 0;

    &__title {
      color: black;
      font-weight: bold;
      font-size: 18px;
      position: relative;
      padding-left: 12px;
      margin: 10px auto;
      //margin: 6px;
      //margin-bottom: 18px;
    }

    &__title::before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 5px;
      height: 100%;
      background-color: #ff3176;
      border-radius: 4px;
    }
  }

  .info_content {
    //border: 1px solid #F2F2F3;
    width: 90%;
    margin: 0 auto;
  }
}

::v-deep .el-form-item__label {
  color: #606266;
  font-weight: bold;
}

::v-deep .el-form-item__content {
  min-width: calc(100% - 80px);
}


::v-deep .el-radio__original {
  display: none !important; /* 隐藏原生 radio 输入，但仍然允许交互 */
}

::v-deep .el-radio:focus:not(.is-focus):not(:active):not(.is-disabled) .el-radio__inner {
  box-shadow: none !important;
}

.kol_logo {
  width: 80px;
  height: 80px;
  display: inline-block;;
  border: 1px solid #F2F2F3;
  border-radius: 50%;
}

.item_data {
  margin: 2px 0;
}

//::v-deep .el-card__body {
//  height: 200px;
//}
.multi-line-ellipsis {
  display: -webkit-box;
  -webkit-line-clamp: 3; /* 显示的行数 */
  -webkit-box-orient: vertical;
  overflow: hidden;
}

</style>

